import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { AuthenicationService } from '../services/authenticate.service';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    // icon: string;
    children?: ChildrenItems[];
    accounttypevisibility: number;
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

//Menu Items

export const ROUTES: RouteInfo[] = [

    // ADMIN
    {
        path: '/dashboard',
        title: 'Welcome',
        type: 'link',
        icontype: 'nc-icon nc-satisfied',
        accounttypevisibility: 1
        //    }, {
        //        path: '/devices',     NO POINT IN HAVING DEVICE IN THE ADMIN CONSOLE
        //        title: 'Device',
        //        type: 'link',
        //        icontype: 'nc-icon nc-mobile',
        //        accounttypevisibility: 1
    }, {
        path: '/photos',
        title: 'Shared Photos',
        type: 'sub',
        collapse: 'photos',
        icontype: 'nc-icon nc-image',
        children: [
            { path: 'imagefiles', title: 'Your Uploaded Images', ab: 'YUI' },
            { path: 'albums', title: 'Shared Albums', ab: 'SA' }            
        ],
        accounttypevisibility: 1
    }, {
        path: '/music',
        title: 'Shared Music',
        type: 'sub',
        collapse: 'music',
        icontype: 'nc-icon nc-note-03',
        children: [
            { path: 'musicfiles', title: 'Your Uploaded Music', ab: 'YUM' },
            { path: 'playlists', title: 'Shared Playlists', ab: 'SP' }        
        ],
        accounttypevisibility: 1
    }, {
        path: '/podcasts',
        title: 'Shared Podcasts',
        type: 'sub',
        collapse: 'podcasts',
        icontype: 'nc-icon nc-headphones',
        children: [
            { path: 'podcastfiles', title: 'Podcast Search', ab: 'PS' },
            { path: 'playlists', title: 'Shared Playlists', ab: 'SP' }            
        ],
        accounttypevisibility: 1
    }, {
        path: '/radio',
        title: 'Shared Radio',
        type: 'sub',
        collapse: 'radio',
        icontype: 'nc-icon nc-note-03',
        children: [
            { path: 'radiofiles', title: 'All Radio Stations', ab: 'ARS' },
            { path: 'playlists', title: 'Shared Playlists', ab: 'SP' },
            { path: 'playlistimages', title: 'Playlist Images', ab: 'PI' }           
        ],
        accounttypevisibility: 1
    }, {
        path: '/users',
        title: 'All Users',
        type: 'link',
        icontype: 'nc-icon nc-single-02',
        accounttypevisibility: 1
    }, {
        path: '/devices/admin',
        title: 'All Devices',
        type: 'link',
        icontype: 'nc-icon nc-mobile',
        accounttypevisibility: 1
    }, {
        path: '/settings/delete',
        title: 'S3 Deletion',
        type: 'link',
        icontype: 'nc-icon nc-settings-gear-65',
        accounttypevisibility: 1
    },

    // STANDARD USER
    {
        path: '/dashboard',
        title: 'Welcome',
        type: 'link',
        icontype: 'nc-icon nc-satisfied',
        accounttypevisibility: 2
    }, {
        path: '/devices',
        title: 'Device',
        type: 'link',
        icontype: 'nc-icon nc-mobile',
        accounttypevisibility: 2
    }, {
        path: '/photos',
        title: 'Photos',
        type: 'sub',
        collapse: 'photos',
        icontype: 'nc-icon nc-image',
        children: [
            { path: 'imagefiles', title: 'All Image Files', ab: 'AIF' },
            { path: 'albums', title: 'Device Albums', ab: 'DA' }            
        ],
        accounttypevisibility: 2
    }, {
        path: '/music',
        title: 'Music',
        type: 'sub',
        collapse: 'music',
        icontype: 'nc-icon nc-note-03',
        children: [
            { path: 'musicfiles', title: 'All Music Files', ab: 'AMF' },
            { path: 'playlists', title: 'Device Playlists', ab: 'DP' }
        ],
        accounttypevisibility: 2
    }, {
        path: '/podcasts',
        title: 'Podcasts',
        type: 'sub',
        collapse: 'podcasts',
        icontype: 'nc-icon nc-headphones',
        children: [
            { path: 'podcastfiles', title: 'Podcast Search', ab: 'PS' },
            { path: 'playlists', title: 'Device Playlists', ab: 'DP' }
        ],
        accounttypevisibility: 2

    }, {
        path: '/radio',
        title: 'Radio',
        type: 'sub',
        collapse: 'radio',
        icontype: 'nc-icon nc-note-03',
        children: [
            { path: 'radiofiles', title: 'All Radio Stations', ab: 'ARS' },
            { path: 'playlists', title: 'Device Playlists', ab: 'PL' }
        ],
        accounttypevisibility: 2
    }, {
        path: '/contacts',
        title: 'Contacts',
        type: 'link',
        icontype: 'nc-icon nc-badge',
        accounttypevisibility: 2
    },


    // PREMIUM - I think!
    {
        path: '/dashboard',
        title: 'Welcome',
        type: 'link',
        icontype: 'nc-icon nc-satisfied',
        accounttypevisibility: 3
    }, {
        path: '/devices',
        title: 'Device',
        type: 'link',
        icontype: 'nc-icon nc-mobile',
        accounttypevisibility: 3
    }, {
        path: '/photos',
        title: 'Photos',
        type: 'sub',
        collapse: 'photos',
        icontype: 'nc-icon nc-image',
        children: [
            { path: 'imagefiles', title: 'All Image Files', ab: 'AIF' },
            { path: 'albums', title: 'Device Albums', ab: 'DA' }            
        ],
        accounttypevisibility: 3
    }, {
        path: '/music',
        title: 'Music',
        type: 'sub',
        collapse: 'music',
        icontype: 'nc-icon nc-note-03',
        children: [
            { path: 'musicfiles', title: 'All Music Files', ab: 'AMF' },
            { path: 'playlists', title: 'Device Playlists', ab: 'DP' }
        ],
        accounttypevisibility: 3
    }, {
        path: '/podcasts',
        title: 'Podcasts',
        type: 'sub',
        collapse: 'podcasts',
        icontype: 'nc-icon nc-headphones',
        children: [
            { path: 'podcastfiles', title: 'Podcast Search', ab: 'PS' },
            { path: 'playlists', title: 'Device Playlists', ab: 'DP' }
        ],
        accounttypevisibility: 3

    }, {
        path: '/radio',
        title: 'Radio',
        type: 'sub',
        collapse: 'radio',
        icontype: 'nc-icon nc-note-03',
        children: [
            { path: 'radiofiles', title: 'All Radio Stations', ab: 'ARS' },
            { path: 'playlists', title: 'Playlists', ab: 'PL' }
        ],
        accounttypevisibility: 3
    }, {
        path: '/contacts',
        title: 'Contacts',
        type: 'link',
        icontype: 'nc-icon nc-badge',
        accounttypevisibility: 3
    },
];


@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent {
    public menuItems: any[];
    accounttype;

    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    constructor(private authenicationService: AuthenicationService) {
        // Account type is 1, 2, or 3. Each menu has a level that can be view
        this.accounttype = this.authenicationService.getUserAccountType();
    }

    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    
}
