import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MustMatch } from '../../../helpers/mustmatch';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from '@angular/forms';
import { User } from '../../../models/user';
import { UserClientService } from '../../../services/user-client.service';
import { AuthenicationService } from '../../../services/authenticate.service';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2'

declare var $: any;

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  myForm: FormGroup;
  @Input() id: string;
  @Input() companyid: string;
  @Input() user: User;

  submitted = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private userClientService: UserClientService,
    private authenicationService: AuthenicationService
  ) {
    //this.createForm(); LEARN: cannot be here as then the user object won't be ready yet
  }

  ngOnInit() {
    this.user = this.authenicationService.getUser();
    console.log('User: ' + JSON.stringify(this.user));
    this.createForm();
  }

  createForm() {
    this.myForm = this.formBuilder.group({
      id: this.user.id,
      firstname: [this.user.firstname, Validators.required],
      lastname: [this.user.lastname, Validators.required],
      email: [this.user.email, Validators.required],
      cellnumber: [this.user.cellnumber, Validators.required],
      password: ['1234567890', Validators.required],
      confirmPassword: ['1234567890', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  get f() {
    return this.myForm.controls;
  }

  updateDetails() {
    this.userClientService
      .update(this.myForm.value)
      .pipe(first())
      .subscribe(
        data => {

          this.user.firstname = this.myForm.value.firstname;
          this.user.lastname = this.myForm.value.lastname;
          this.user.email = this.myForm.value.email;
          this.user.cellnumber = this.myForm.value.cellnumber;
          this.authenicationService.setUser(this.user);

          Swal.fire({
            title: "Successfully updated your account",
            type: 'success',
            heightAuto: false
          }).then((result) => {
            this.activeModal.close(this.myForm.value);
          })
          console.log('Success updating user: ' + JSON.stringify(data));

        },
        error => {
          // Change for the cool notification
          Swal.fire({
            title: "Error",
            text: "Cannot connect to the database, if this continues please contact support@codered.org.za",
            type: 'warning',
            heightAuto: false
          }).then((result) => {
          })
        }
      );
  }

  submitForm() {
    this.submitted = true;

    if (this.myForm.invalid) {
      return;
    }

    this.updateDetails();
  }

  closeModal() {
    this.activeModal.close('Modal - yeeehaaa');
  }
}

