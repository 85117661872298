import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TourComponent } from './tour.component';
import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';

@NgModule({
  declarations: [
    TourComponent,
    WelcomeModalComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [ TourComponent ],
  entryComponents: [ WelcomeModalComponent ]
})
export class TourModule { }
