<div class="modal-content">
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <i class="nc-icon nc-simple-remove"></i>
        </button>
        <img class="faimg" src="/assets/img/famdamily/falogo-orange.png">
        <h5 class="modal-title">Welcome to The family App!</h5>
    </div>

    <div class="modal-body">
        Thank you for registering! We highly recommend you take the one minute tour of where to find everything.
    </div>

    <div class="footer text-center">
        <button type="submit" class="btn btn-fill btn-success btn-wd" (click)="startTour()">
            Take The One Minute Tour
        </button>
    </div>
</div>