<div class="modal-content">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <i class="nc-icon nc-simple-remove"></i>
    </button>

    <h5 class="modal-title">
      <i class="nc-icon nc-single-02"></i>
      Edit Your Account Details</h5>
  </div>

  <div class="modal-body">
    <form [formGroup]="myForm" (ngSubmit)="submitForm()">
      
      <div class="">
        <div class="content">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="myform">First Name
                  <span class="star" style="color: red;">*</span>
                </label>
                <input maxlength="255" type="text" formControlName="firstname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                  <div *ngIf="f.firstname.errors.required">
                    First Name is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="myform">Last Name
                  <span class="star" style="color: red;">*</span>
                </label>                    
                <input maxlength="255" type="text" formControlName="lastname" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                  <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="myform">Email
                  <span class="star" style="color: red;">*</span>
                </label>   
                <input disabled type="email" formControlName="email" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">
                    Email must be a valid email address
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="myform">Cellular Number
                  <span class="star" style="color: red;">*</span>
                </label>
                <input type="tel" formControlName="cellnumber" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.cellnumber.errors }" />
                <div *ngIf="submitted && f.cellnumber.errors" class="invalid-feedback">
                  <div *ngIf="f.cellnumber.errors.required">Cellular number is required</div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="myform">Password
                  <span class="star" style="color: red;">*</span>
                </label>   
                <input type="password" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                  <div *ngIf="f.password.errors.minlength">
                    Password must be at least 6 characters
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label class="myform">Confirm Password
                  <span class="star" style="color: red;">*</span>
                </label>   
                <input type="password" formControlName="confirmPassword" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">
                    Confirm Password is required
                  </div>
                  <div *ngIf="f.confirmPassword.errors.mustMatch">
                    Passwords must match
                  </div>
                </div>
              </div> 
            </div>
          </div>

        </div>
        <div class="footer text-center">
          <button type="submit" class="btn btn-warning">
            Update Details
          </button>
        </div>
      </div>
    </form>
  </div>
</div>