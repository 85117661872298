<nav #navbar class="navbar navbar-expand-md navbar-absolute" [ngClass]="{ 'bg-white': open === true , 'navbar-transparent': open === false}">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button id="minimizeSidebar" style="background-color: #E68103" class="minimiseBar btn btn-icon btn-round" (click)="minimizeSidebar()">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
      </div>
      <div class="navbar-toggle" (click)="sidebarToggle()">
        <button type="button" class="navbar-toggler">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
      <div *ngIf="isLoading | async" class="ml-4 spinner-border" style="color: #e88d19;" role="status"></div>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" (click)="open = !open"
      >
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
      <span class="navbar-toggler-bar navbar-kebab"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">

      <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link btn-rotate" (click)="editUser()">
              <i class="nc-icon nc-single-02"></i> {{ username }}
  
            </a>
          </li>
        <li class="nav-item">
          <a class="nav-link btn-rotate" (click)="logout()">
            <i class="nc-icon nc-settings-gear-65"></i> Logout
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
