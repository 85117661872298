<div *ngIf="showTour">
    <div *ngIf="tourStep > 0" class="headingtext">The Family App Tour</div>
    <div *ngIf="tourStep > 0" class="closebutton" (click)="close()">X</div>
    <div *ngIf="tourStep > 0" class="stepstext">Step {{tourStep}} of 9</div>

    <!--
    <div *ngIf="tourStep === 1" class="step1" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 1">
        <div class="step1line"></div>
        <div class="tourContainer" style="background-color: #000000c4">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Side Menu Overview</h1>
                <h2>The side menu is where it all happens! Use it to manage all your content and playlists, from photos,
                    music, podcasts, radio stations, and even phone book contacts.
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>
    -->

    <div *ngIf="tourStep === 1" class="highlight" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 1">
        <div class="tourContainer">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Side Menu Overview</h1>
                <h2>The side menu is where it all happens. Use it to manage all your content, from photos
                    music, podcasts, radio, stations and even phone-book contacts.
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="tourStep === 2" class="highlight" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 2">
        <div class="tourContainer">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Manage Your Content</h1>
                <h2>For each content type there is a menu item to upload and store your content, and a dedicated
                    menu item to view your playlists.
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="goBack()">
                    Previous
                </button>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="tourStep === 3" class="highlight" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 3">
        <div class="tourContainer">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Step 1 - Upload Your Files</h1>
                <h2>Begin by uploading files to create a library. For audio these can be mp3, mp4, aac, or wav. For
                    photos use jpeg or png.
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="goBack()">
                    Previous
                </button>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="tourStep === 4" class="highlight" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 4">
        <div class="tourContainer">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Step 2 - Create Your Playlists</h1>
                <h2>Next, create playlists from your uploaded files. These playlists will be synchronized to the app on
                    the device.
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="goBack()">
                    Previous
                </button>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>

    <!--
    <svg *ngIf="tourStep === 5" width="24" height="24" class="arrow" [style.top.px]="rectangle_top-50" [style.left.px]="rectangle_left-50" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z"/></svg>
-->
    <svg *ngIf="tourStep === 5" class="arrow" [style.top.px]="rectangle_top-130" [style.left.px]="rectangle_left-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 350 100">
        <defs>
          <marker id="arrowhead" markerWidth="10" markerHeight="7" 
          refX="0" refY="3.5" orient="auto">
            <polygon points="0 0, 10 3.5, 0 7" />
          </marker>
        </defs>
        <line x1="0" y1="50" x2="250" y2="50" stroke="#FFF" 
        stroke-width="8" marker-end="url(#arrowhead)" />
      </svg>

    <div *ngIf="tourStep === 5" class="highlight" [style.top.px]="rectangle_top-10" [style.left.px]="rectangle_left-10"
        [style.width.px]="rectangle_width+20" [style.height.px]="rectangle_height+20"
        style="box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.7) !important;"></div>
    <div *ngIf="tourStep === 5">
        <div class="tourContainer" style="background-color: initial;">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Use the Menu!</h1>
                <h2>The menu icon on each page is what you use to add, edit, and delete items ... nom nom
                    nom!
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="goBack()">
                    Previous
                </button>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>

    <!--
    <div *ngIf="tourStep === 6" class="highlight" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 6">
        <div class="tourContainer">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Create Your Albums</h1>
                <h2>Similarly the albums you create are downloaded and synchronized to your device.
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd" (click)="goBack()">
                    Previous
                </button>
                <button class="btn btn-fill btn-outline-light btn-wd" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>
    -->


    <div *ngIf="tourStep === 6" class="highlight" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 6">
        <div class="tourContainer">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Radio and Podcasts</h1>
                <h2>As with music and photos, you can add awesome radio and podcast content. No need to upload though,
                    all the content is provided.
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="goBack()">
                    Previous
                </button>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="tourStep === 7" class="highlight" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 7">
        <div class="tourContainer">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Create some Contacts</h1>
                <h2>You can create and manage contacts that are easily viewable and dialable via the app. If Whatsapp is
                    installed on the device, the user will be taken directly to the conversation thread allowing them to chat or start a voice
                    or video call.
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="goBack()">
                    Previous
                </button>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="tourStep === 8" class="highlight" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 8">
        <div class="tourContainer">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Add a Device</h1>
                <h2>Use the Device menu to add a device if one has not been added already. It will show you how to
                    download the app, and provide you with a linking code that you can use to
                    connect the app. Once linked, this menu will provide useful ongoing device statistics.
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="goBack()">
                    Previous
                </button>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="progressTour()">
                    Next
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="tourStep === 9" class="highlight" [style.top.px]="rectangle_top" [style.left.px]="rectangle_left"
        [style.width.px]="rectangle_width" [style.height.px]="rectangle_height"></div>
    <div *ngIf="tourStep === 9">
        <div class="tourContainer">
            <div style="padding: 10%; margin-top: 50px;">
                <h1>Thank You!</h1>
                <h2>Congratulations on getting though the tutorial. Now go and play with the platform and have some fun :)
                </h2>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="goBack()">
                    Previous
                </button>
                <button class="btn btn-fill btn-outline-light btn-wd mx-1" (click)="progressTour()">
                    Finish
                </button>
            </div>
        </div>
    </div>
</div>