import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import { UserModalComponent } from './user-modal/user-modal.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [ RouterModule, CommonModule, FormsModule, ReactiveFormsModule ],
    declarations: [ NavbarComponent, UserModalComponent ],
    exports: [ NavbarComponent ],
    entryComponents: [UserModalComponent]
})

export class NavbarModule {}
