import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenicationService {

  private url = environment.apiurl;
  private user;
  public showtutorial = false;
  private tourSubject = new Subject<any>();

  constructor(private http: HttpClient, private router: Router) {
    console.log('AuthenicationService Constructor');
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(this.url + `/users/authenticate`, {
        email: email,
        password: password
      }) // using pipe here so that we can get the data out before passing on
      .pipe(
        map(user => {
          console.log("User logging in: " + JSON.stringify(user));
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // alert('Looks good: ' + user.token);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('famdamily.currentUser', JSON.stringify(user));
            this.user = user;

            if (user.showtutorial) {
              this.showtutorial = true;
            } else {
              this.showtutorial = false;
            }
          }

          return user;
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('famdamily.currentUser');
  }

  passwordRequest(email: string) {
    const obj = { 'email': email };
    return this.http.post(<any>this.url + `/users/client/passwordresetrequest`, obj);
  }

  passwordReset(password: string, passwordresetcode: string) {
    const obj = { 'password': password, 'passwordresetcode': passwordresetcode };
    console.log('passwordresetchange: ' + JSON.stringify(obj));
    return this.http.post(<any>this.url + `/users/client/passwordresetchange`, obj);
  }

  setViewedTour() {
    console.log('Sending tour viewed to server');
    return this.http.put(this.url + `/users/client/canceltutorial/` + this.user.id, 'true');
  }

  getTourObservable(): Observable<any> {
    // so that tour component can be launched from dashboard component
    return this.tourSubject.asObservable();
  }

  sendTourObservable() {
    this.tourSubject.next();
  }

  getUserName() {
    if (this.user) {
      return this.user.firstname + ' ' + this.user.lastname;
    } else {
      console.log('WARNING: User is null, must have been a refresh, reload from localstorage');
      const currentUser = JSON.parse(localStorage.getItem('famdamily.currentUser'));
      if (!currentUser) {
        this.router.navigate(['/pages/login']);
      }
      this.user = currentUser;
      return this.user.firstname + ' ' + this.user.lastname;
    }
  }

  getUserAccountType() {
    if (this.user) {
      return this.user.accounttype;
    } else {
      console.log('WARNING: , must have been a refresh, reload from localstorage');
      const currentUser = JSON.parse(localStorage.getItem('famdamily.currentUser'));
      if (!currentUser) {
        this.router.navigate(['/pages/login']);
        return;
      }
      this.user = currentUser;
      return this.user.accounttype;
    }
  }

  isServerUser() {
    const accounttype = this.getUserAccountType();
    console.log('Account type: ' + accounttype);
    if (accounttype == 1) {
      return true;
    } else {
      return false;
    }
  }

  getUserDevices() {
    if (this.user) {
      return this.user.devices;
    } else {
      console.log('WARNING: , must have been a refresh, reload from localstorage');
      const currentUser = JSON.parse(localStorage.getItem('famdamily.currentUser'));
      if (!currentUser) {
        this.router.navigate(['/pages/login']);
        return;
      }
      this.user = currentUser;
      return this.user.devices;
    }
  }

  tempAddUserDeviceForStats(deviceid) {
    // used when you create a new device, because you don't logout, need this as the devices are only refreshed when you login
    const obj = { 'id': deviceid };
    this.user.devices.push(obj);
    console.log("NEW DEVICES AFTER ADDING: " + JSON.stringify(this.user.devices));

    localStorage.setItem('famdamily.currentUser', JSON.stringify(this.user));
  }

  tempRemoveUserDeviceForStats(deviceid) {
    // when you login in, devices in current storage get refreshed, so all good. But since we are not logging in now, need to remove
    for (let i = 0; i < this.user.devices.length; i++) {
      if (this.user.devices[i].id === deviceid) {
        this.user.devices.splice(i, 1);
        break;
      }
    }
    console.log("NEW DEVICES AFTER REMOVING: " + JSON.stringify(this.user.devices));

    localStorage.setItem('famdamily.currentUser', JSON.stringify(this.user));
  }

  getUserID() {
    if (this.user) {
      return this.user.id;
    } else {
      console.log('WARNING: User is null, must have been a refresh, reload from localstorage');
      const currentUser = JSON.parse(localStorage.getItem('famdamily.currentUser'));
      if (!currentUser) {
        this.router.navigate(['/pages/login']);
        return;
      }
      this.user = currentUser;
      return this.user.id;
    }
  }

  getUser() {
    return this.user;
  }

  setUser(user) {
    this.user = user;
  }

  getS3AccessKey() {
    return this.user.s3accesskey;
  }

  getS3Region() {
    return this.user.s3region;
  }

  getS3ImagesEndpoint() {
    return this.user.s3imagesendpoint;
  }

  getS3ImagesBucket() {
    return this.user.s3imagesbucket;
  }

  getS3MusicEndpoint() {
    return this.user.s3musicendpoint;
  }

  getS3MusicBucket() {
    return this.user.s3musicbucket;
  }


  /*
  isMusicRoyaltyfreeFirstUseCancelled() {
    const val = JSON.parse(localStorage.getItem('famdamily.musicRoyaltyFreeCancelled'));
    if (val && JSON.parse(val) === true) {
      return true;
    } else {
      return false;
    }
  }
  
  setMusicRoyaltyfreeFirstUseCancelled() {
    localStorage.setItem('famdamily.musicRoyaltyFreeCancelled', JSON.stringify(true)) 
  }
  */

}
