import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthenicationService } from '../../services/authenticate.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WelcomeModalComponent } from './welcome-modal/welcome-modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss']
})
export class TourComponent implements OnInit, AfterViewInit {

  showTour = false;
  tourStep = 0;

  rectangle_top = 0;
  rectangle_left = 0;
  rectangle_width = 0;
  rectangle_height = 0;

  constructor(private authenicationService: AuthenicationService, private modalService: NgbModal) { }

  ngOnInit(): void {
    console.log('TourComponent: ngOnInit');
  }

  ngAfterViewInit() {

    if (this.authenicationService.showtutorial  && !this.authenicationService.isServerUser()) {
      this.showWelcomeModal();
    }

    this.authenicationService.getTourObservable().subscribe(data => {
      console.log('Tour subscription received');
      
      this.tourStep = 0;
      this.showTour = true;
      this.progressTour();
    });
  }

  close() {
    Swal.fire({
      title: 'End the tour?',
      text: 'Would you like to end the tour?',
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        this.skipOrCompleteTour();
      }
    })
  }


  showWelcomeModal() {
    console.log('Open welcome modal');
    const modalRef = this.modalService.open(WelcomeModalComponent, { size: 'lg' });

    modalRef.result.then((result) => {
      console.log("Returned from modalservice open: " + result);

      if (result === 'Close') {
        this.skipOrCompleteTour();
      } else {
        this.showTour = true;
        this.progressTour();
      }

    }).catch((error) => {
      console.log(error);
    });
  }

  checkAllClosed() {
    // check if any child menu items showing, close if is
    if (document.getElementById("Photos").attributes.getNamedItem("aria-expanded") != null) {
      if (document.getElementById("Photos").attributes.getNamedItem("aria-expanded").value === 'true') {
        document.getElementById("Photos").click();
      }
    }
    if (document.getElementById("Music").attributes.getNamedItem("aria-expanded") != null) {
      if (document.getElementById("Music").attributes.getNamedItem("aria-expanded").value === 'true') {
        document.getElementById("Music").click();
      }
    }
    if (document.getElementById("Podcasts").attributes.getNamedItem("aria-expanded") != null) {
      if (document.getElementById("Podcasts").attributes.getNamedItem("aria-expanded").value === 'true') {
        document.getElementById("Podcasts").click();
      }
    }
    if (document.getElementById("Radio").attributes.getNamedItem("aria-expanded") != null) {
      if (document.getElementById("Radio").attributes.getNamedItem("aria-expanded").value === 'true') {
        document.getElementById("Radio").click();
      }
    }
  }

  skipOrCompleteTour() {
    this.showTour = false;

    this.authenicationService
      .setViewedTour()
      .subscribe(
        data => {
          console.log('Updated server not to show tour again');
        }, error => {
          console.log('Error updaing server not to show tour again: ' + error);
        }
      );
  }

  progressTour() {
    this.tourStep++;
    this.draw(true);
  }

  goBack() {
    this.tourStep--;
    this.draw(false);
  }

  draw(mustclick) {
    if (this.tourStep === 1) {

      this.checkAllClosed();

      document.getElementById("Welcome").click();

      const rect = document.getElementById("Welcome").getBoundingClientRect();
      const rect2 = document.getElementById("Contacts").getBoundingClientRect();
      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect2.bottom - rect.top;
    }

    if (this.tourStep === 2) {
      if (mustclick) {
        document.getElementById("Music").click();
        document.getElementById("Music All Music Files").click();
      }

      const rect = document.getElementById("Music").getBoundingClientRect();
      const rect2 = document.getElementById("Music Device Playlists").getBoundingClientRect();

      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect2.bottom - rect.top;
    }

    if (this.tourStep === 3) {
      document.getElementById("Music All Music Files").click();

      const rect = document.getElementById("Music All Music Files").getBoundingClientRect();

      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect.height;
    }

    if (this.tourStep === 4) {
      document.getElementById("Music Device Playlists").click();
      const rect = document.getElementById("Music Device Playlists").getBoundingClientRect();

      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect.height;
    }

    if (this.tourStep === 5) {
      const rect = document.getElementById("menu-share").getBoundingClientRect();

      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect.height;
    }

    /*
    if (this.tourStep === 6) {
      document.getElementById("Photos").click();
      document.getElementById("Photos Device Albums").click();
      const rect = document.getElementById("Photos Device Albums").getBoundingClientRect();

      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect.height;
    } */

    if (this.tourStep === 6) {
      const rect = document.getElementById("Podcasts").getBoundingClientRect();
      const rect2 = document.getElementById("Radio").getBoundingClientRect();

      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect2.bottom - rect.top;
    }

    if (this.tourStep === 7) {
      document.getElementById("Contacts").click();
      const rect = document.getElementById("Contacts").getBoundingClientRect();

      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect.height;
    }

    if (this.tourStep === 8) {
      document.getElementById("Device").click();
      const rect = document.getElementById("Device").getBoundingClientRect();

      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect.height;
    }

    if (this.tourStep === 9) {
      document.getElementById("Welcome").click();
      const rect = document.getElementById("Welcome").getBoundingClientRect();

      this.rectangle_top = rect.top;
      this.rectangle_left = rect.left;
      this.rectangle_width = rect.width;
      this.rectangle_height = rect.height;
    }

    if (this.tourStep === 10) {
      this.showTour = false;
    }
  }
}
