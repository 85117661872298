import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserClientService {
  
  private url = environment.apiurl;

  constructor(private http: HttpClient) {
      console.log('UserService Constructor');
   }

  add(user: User) {
      console.log('Trying to add: ' + JSON.stringify(user));
      return this.http.post(<any>this.url + `/users/client/add`, user);
  }

  update(user: User) {
      console.log('Trying to update: ' + JSON.stringify(user));
      return this.http.put(this.url + `/users/client/` + user.id, user);
  }

  delete(id: string) {
      return this.http.delete(this.url + `/users/client/` + id);
  }
}
