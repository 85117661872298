import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuard } from './helpers/auth.guard';

export const AppRoutes: Routes = [{
    path: '',
    redirectTo: 'devices',
    pathMatch: 'full',
}, {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [{
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    }, {
        path: 'devices',
        loadChildren: () => import('./devices/devices.module').then(m => m.DevicesModule)
    }, {
        path: 'photos',
        loadChildren: () => import('./photos/photos.module').then(m => m.PhotosModule)
    }, {
        path: 'music',
        loadChildren: () => import('./music/music.module').then(m => m.MusicModule)
    }, {
        path: 'podcasts',
        loadChildren: () => import('./podcasts/podcasts.module').then(m => m.PodcastsModule)
    }, {
        path: 'radio',
        loadChildren: () => import('./radio/radio.module').then(m => m.RadioModule)
    }, {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
    }, {
        path: 'contacts',
        loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
    }, {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
    }]
}, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
    }]
}
];
