import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenicationService } from '../services/authenticate.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenicationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const errorMessage = err.error.message || err.statusText;
        console.log('Error message: ' + errorMessage);
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();

          if (errorMessage === 'Expired token') {
            alert("Your session has expired, please log in again!");
          } else {
            alert(errorMessage);
          }
          
          location.reload(true);
        }
        console.log('Error Interceptor ' + JSON.stringify(err));

        
        return throwError(errorMessage);
      })
    );
  }
}
