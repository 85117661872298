
<div class="wrapper">
    <div class="sidebar" data-color="famdamily-orange" data-active-color="white">
        <sidebar-cmp></sidebar-cmp>
        <div class="sidebar-background" style="background-image: url(assets/img/famdamily/background-1.jpg)"></div>
    </div>
    <div class="main-panel">
        <navbar-cmp></navbar-cmp>
        <router-outlet></router-outlet>
        <div *ngIf="!isMap()">
            <footer-cmp></footer-cmp>
        </div>
    </div>

</div>

 <!-- <fixedplugin-cmp></fixedplugin-cmp> -->

 <app-tour></app-tour>
