import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from "rxjs/operators";
import { LoaderService } from '../services/loader.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(public loaderService: LoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        //console.log('INTERCEPTING');
        this.loaderService.show();

        const currentUser = JSON.parse(localStorage.getItem('famdamily.currentUser'));
        if (currentUser && currentUser.token) {
            //console.log('SETTING HEADER');
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        //return next.handle(request);
        return next.handle(request).pipe(
            finalize(() => this.loaderService.hide())
        );
    }
}